import { render, staticRenderFns } from "./sright22.vue?vue&type=template&id=5f3bc462&scoped=true&"
import script from "./sright22.vue?vue&type=script&lang=js&"
export * from "./sright22.vue?vue&type=script&lang=js&"
import style0 from "./sright22.vue?vue&type=style&index=0&id=5f3bc462&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f3bc462",
  null
  
)

export default component.exports